// Third party
import React from 'react';
import PropTypes from 'prop-types';
import Link from 'gatsby-link';
import styled from 'styled-components'

// Components
import NavBar from './NavBar'
import Container from './Container'

import theme from '../theme'

const HeaderWrapper = styled.div`
  background-color: ${theme.brandSecondaryDark};
  margin-bottom: 20px;
  min-height: 50px;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1000;
  a {
    color: ${theme.brandPrimary};
    text-decoration: none;
    display: block;
    padding: 15px 0.5em;
    line-height: 20px;
    height: 50px;
  }
`;

const Header = (props) => {
  const navItems = [
    { 
      id: 1,
      title: "Docenten",
      url: '/teachers/'
    },
    { 
      id: 2,
      title: "Blog",
      url: '/blog/'
    },
    { 
      id: 3,
      title: "Testimonials",
      url: '/testimonials/'
    },
  ]
  return (
    <HeaderWrapper>
      <Container>
        <Link
          to="/"
          style={{
            float: 'left'
          }}
        >
          {props.title}
        </Link>
        <NavBar items={navItems} />
      </Container>
    </HeaderWrapper>
    )
};

export default Header;

Header.propTypes = {
  title: PropTypes.string
}
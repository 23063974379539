// Third party
import React from "react";
import PropTypes from "prop-types";
import { StaticQuery, graphql } from "gatsby";
import Helmet from "react-helmet";

// Proprietary components
import Header from "../Header";
import Footer from "../Footer";

/* Styles */
import "./index.css";
import "toast-grid/css/grid.css";

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query LayoutQuery {
        site {
          siteMetadata {
            title
          }
        }
        allContentfulTestimonial(filter: { node_locale: { eq: "nl" } }) {
          edges {
            node {
              id
              title
              slug
            }
          }
        }
        allContentfulPost(filter: { node_locale: { eq: "nl" } }) {
          edges {
            node {
              id
              title
              slug
            }
          }
        }
        allContentfulPage(filter: { node_locale: { eq: "nl" } }) {
          edges {
            node {
              id
              title
              slug
            }
          }
        }
      }
    `}
    render={data => (
      <>
        <Helmet
          script={[
            {
              type: "text/javascript",
              src: "//d2wy8f7a9ursnm.cloudfront.net/v4/bugsnag.min.js",
            },
            {
              type: "text/javascript",
              innerHTML:
                'window.bugsnagClient = bugsnag("292383c00886b30284d54d4d3a9ea015")',
            },
          ]}
        />
        <Header title={data.site.siteMetadata.title} />
        {children}
        <Footer {...data} />
      </>
    )}
  />
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;

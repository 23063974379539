import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { StaticQuery, graphql } from "gatsby";

function SEO({
  description,
  lang,
  meta,
  title,
  url,
  image,
  author,
  createdAt,
  updatedAt,
  type,
}) {
  return (
    <StaticQuery
      query={detailsQuery}
      render={data => {
        const metaDescription =
          description || data.site.siteMetadata.description;
        const metaTitle =
          title ||
          `${data.site.siteMetadata.title}: ${data.site.siteMetadata.tagLine}`;

        const metaAuthor = author || data.site.siteMetadata.author;
        const schemaOrgJSONLD =
          type === "article"
            ? JSON.stringify({
                "@context": "http://schema.org",
                "@type": "Article",
                headline: metaTitle,
                datePublished: createdAt,
                dateModified: updatedAt,
                image: image,
                url: url,
                publisher: {
                  "@type": "Organisation",
                  name: data.site.siteMetadata.title,
                  logo: "",
                },
                author: {
                  "@type": "Person",
                  name: metaAuthor.title,
                },
              })
            : JSON.stringify({
                "@context": "http://schema.org",
                "@type": "Organistion",
                name: data.site.siteMetadata.title,
                url: data.site.siteMetadata.siteUrl,
                author: {
                  "@type": "Founder",
                  name: metaAuthor.title,
                },
              });
        return (
          <Helmet
            htmlAttributes={{
              lang,
            }}
            title={metaTitle}
            titleTemplate={`%s`}
            link={[
              {
                rel: "canonical",
                href: url,
              },
            ]}
            meta={[
              {
                name: `description`,
                content: metaDescription,
              },
              {
                name: `og:url`,
                content: url,
              },
              {
                name: `og:image`,
                content: image,
              },
              {
                property: `og:title`,
                content: metaTitle,
              },
              {
                property: `og:description`,
                content: metaDescription,
              },
              {
                property: `og:type`,
                content: type,
              },
              {
                name: `twitter:image`,
                content: image,
              },
              {
                name: `twitter:card`,
                content: `summary`,
              },
              {
                name: `twitter:creator`,
                content: metaAuthor.twitterUsername,
              },
              {
                name: `twitter:title`,
                content: metaTitle,
              },
              {
                name: `twitter:description`,
                content: metaDescription,
              },
              {
                name: "google-site-verification",
                content: data.site.siteMetadata.siteVerification,
              },
              {
                name: "theme-color",
                content: data.site.siteMetadata.themeColor,
              },
            ].concat(meta)}
          >
            <script type="application/ld+json">{schemaOrgJSONLD}</script>
          </Helmet>
        );
      }}
    />
  );
}

SEO.defaultProps = {
  lang: `nl`,
  meta: [],
  image: "",
  type: `website`,
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  title: PropTypes.string,
  image: PropTypes.string,
  url: PropTypes.string.isRequired,
  author: PropTypes.shape({
    title: PropTypes.string,
    twitterUsername: PropTypes.string,
  }),
  createdAt: PropTypes.string,
  updatedAt: PropTypes.string,
};

export default SEO;

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        title
        tagLine
        siteUrl
        description
        siteVerification
        author {
          title
          twitterUsername
        }
        themeColor
      }
    }
  }
`;
